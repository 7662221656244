import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from './LoadingSpinner';
import configColor from 'configs/configColor';
import { breakpointsMedias } from 'configs/breakpoints';

interface IB extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    text: string,
    isLoading?: boolean,
    sizeBt?: "small" | "normal",
    className?: string,
    disabled?: boolean,
}

const Button = ({ text, isLoading = false, sizeBt = "normal", className, disabled = false, ...props }: IB) => {
    const { t } = useTranslation();
    return (
        <Wrap className={`${isLoading ? "bt-loading" : ""} bt-${sizeBt} ${className} `} disabled={disabled || isLoading} {...props}>
            <div>
                <span className={`color-white size-2`}>{!!isLoading ? <LoadingSpinner />
                    : <>
                        {t(text)}
                    </>}</span>
            </div>

        </Wrap>
    )
}
export default Button

const Wrap = styled.button`
    /* max-width: 100%; */
    min-width: 100px;
    height: 30px;
    &.bt-small {
        height: 28px;
        border-radius: 6px;
        padding: 0 7px;
        min-width: 68px;
    }
    > div {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 12px;
        width: 100%;
        height: 100%;
        z-index: 1;
        position: relative;
        background: #7BBC3A;
        border: 1px solid #FFFFFF;
        border-radius: 999px;
        transition: 0.3s;
        > span {
            transition: 0.3s;
            font-family: 'Museo-700';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
            ${breakpointsMedias.min1200} {
                font-size: 20px;
                line-height: 32px;
            }
        }
        &:hover {
            background: #FFFFFF;
            border: 1px solid #7BBC3A;
            > span {
                color: #7BBC3A;
            }
        }
    }
  
    /* &:active,
    &:focus, */
   
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &:disabled{
        opacity: 0.4;
        cursor: not-allowed;
    }
    &.bt-loading {
        opacity: 0.4;
        cursor: not-allowed;
        :hover {
            background: ${configColor.colorBG};
            border: 1px solid ${configColor.colorPrimary};
        }
    }
    @keyframes rotate-center-2 {
        0% {
            transform: rotate(0);
        }
        100% {
           transform: rotate(360deg);
        }
    }
    ${breakpointsMedias.min1200} {
        min-width: 182px;
        height: 51px;
        &.bt-small {
            height: 32px;
        }
        > div {
            border: 3px solid #FFFFFF;
            &:hover {
                border: 3px solid #7BBC3A;
            }
        }
    }
`