import { createGlobalStyle } from "styled-components";
import { breakpointsMedias } from "./breakpoints";
import configColor from "./configColor";
import bgHome from 'assets/images/bg-home.png'
import bgHome2 from 'assets/images/bg-home-2.png'

export const GlobalStyle = createGlobalStyle`
  :root{
    font-size:14px;
    line-height:1.254;
    letter-spacing: 0.15px;
  }
  #root{
    background: linear-gradient(225.26deg, rgba(0, 0, 0, 0.77) -1.37%, #9758ED 90.97%);
    background-image: url(${bgHome});
    background-size: cover;
    background-position: center top;
  }
  body {
    background-color: transparent;
    /* background-color: ${configColor.bgPrimary}; */
  }
  .container {
    width: 100%;
    padding: 0 100px;
    max-width: 1440px;
  }
  ${breakpointsMedias.max1199}{
      .container {
        padding: 0 40px;
      }
  }
  ${breakpointsMedias.max767}{
      .container {
        padding: 0 16px;
      }
      #root{
        background-image: url(${bgHome2});
      }
  }
`;
