import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import configColor from 'configs/configColor';
import { breakpointsMedias } from 'configs/breakpoints'
import Button from 'components/core/Button';
import bgMenu from 'assets/images/bg-home.png'
import { useState } from 'react';
import ModalComingSoon from 'pages/home/ModalComingSoon';

interface IMenu {
    onClose: () => void
}

export const MENU_LIST = [
    {
        text: "about",
        link: "#about"
    },
    {
        text: "nftworld",
        link: "#nftworld",
    },
    {
        text: "tokenomics",
        link: "#tokenomics",
    },
    {
        text: "howToBuy",
        link: "#howToBuy",
    },
    {
        text: "roadmap",
        link: "#roadmap",
    },
];



const Menu = ({ onClose }: IMenu) => {
    // const { pathname } = useLocation();
    const [show, setShow] = useState<boolean>(false)
    const { t } = useTranslation()

    return (
        <Wrap onClick={(e) => { e.stopPropagation() }}>
            <div className="bg-net"></div>
            {/* <img src={ballLight} alt="" className='bg-light' /> */}
            <div className={`menu-list`}>
                {
                    MENU_LIST.map((item, index) =>
                        <a href={item.link}
                            key={index}
                            className={`menu-item`}
                            onClick={() => {
                                onClose()
                            }}
                        >
                            <span className={`color-white`}>{t(item.text)}</span>
                        </a>)
                }
                <Button text='BUY NOW' />
            </div>
            {show && <ModalComingSoon onClose={() => { setShow(false) }} />}
        </Wrap>
    )
}

export default Menu

const Wrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 60px);
    background-image: url(${bgMenu});
    background-size: 100% 110%;
    background-position: bottom center;
    /* background-color: #000000; */
    position: relative;
    /* margin-top: auto; */
    max-width: 280px;
    margin-left: auto;
    .bg-net {
        height: 100%;
        position: absolute;
        width: 100%;
        opacity: 0.5;
    }
    .bg-light {
        position: absolute;
        width: 100%;
    }
    .menu-list {
        display: flex;
        flex-direction: column;
        width: 100%;
        /* padding: 72px 0; */
        z-index: 0;
        height: 100%;
        
        .menu-item {
            cursor: pointer;
            display: flex;
            align-items: center;
            height: 60px;
            justify-content: flex-end;
            padding: 0 16px;
            > span {
                text-transform: uppercase;
                opacity: 0.8;
                color: white;
                font-family: 'Museo-800';
                font-style: normal;
                font-weight: 800;
                font-size: 16px;
                line-height: 33px;
            }
            &:hover {
                > span {
                    color: ${configColor.colorGreen};
                    opacity: 1;
                }
            }
        }
        > button {
            width: 120px;
            margin-left: auto;
            margin-right: 16px;
        }
    }
    ${breakpointsMedias.min1200} {
        flex-direction: row;
        background-color: transparent;
        background-image: none;
        height: 100%;
        width: 100%;
        max-width: unset;
        .menu-list {
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 0;
            .menu-item {
                padding: 0 15px;
                height: 100%;
                > span {
                    opacity: 0.8;
                }
            }
            > button {
                margin-left: auto;
                display: none;
            }
        }
    }
    ${breakpointsMedias.min1600} {
        .menu-list {
            .menu-item {
                padding: 0 15px;
                > span {
                    font-family: 'Museo-800';
                    font-style: normal;
                    font-weight: 800;
                    font-size: 16px;
                    line-height: 33px;
                }
            }
            .social-item {
                width: 120px;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                margin-left: auto;
                .bg-social {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: auto;
                    z-index: 0;
                }
                > span {
                    margin-top: 74px;
                    text-align: center;
                    color: #000000;
                    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
                    line-height: 20px;
                    z-index: 1;
                    font-family: "BlaakBold";
                    
                }
            }
        }
    }
`

interface ISocicalItem {
    active: string
}

const SocicalItem = styled.div`
    background-color: #828282;
    -webkit-mask: url(${({ active }: ISocicalItem) => active}) no-repeat center;
    mask: url(${({ active }: ISocicalItem) => active}) no-repeat center;
    mask-size: contain;
    /* transition: 0.6s ease-in-out; */
    &:hover {
        background: linear-gradient(135deg, #FE8C00 0%, #F83600 100%);
    }
`